import { INavItem } from "@/types/routing";
import { HomeIcon, UsersIcon, WalletIcon, GaugeIcon } from "lucide-react";

import BattleIcon from "@/assets/svg/battle.svg?react";
import PodiumIcon from "@/assets/svg/podium-icon.svg?react";
import { BATTLES, WALLET, PROFILE, LEADERBOARD } from "@/constants/routes";
import { ProfileScreens } from "@/pages/profile/components/Menu";

export const desktopNavItems: Array<INavItem> = [
  {
    namePath: "menu.battles",
    path: BATTLES,
    icon: <BattleIcon />,
  },
  {
    namePath: "menu.wallet",
    path: WALLET,
    authProtected: true,
    icon: <WalletIcon />,
  },
  {
    namePath: "menu.profile",
    path: PROFILE,
    authProtected: true,
    icon: <UsersIcon />,
  },
  {
    namePath: "menu.hypeboards",
    path: "/hype-boards",
    icon: <GaugeIcon />,
  },
  {
    namePath: "menu.leaderboard",
    path: LEADERBOARD,
    icon: <PodiumIcon />,
  },
];

interface MobileNavItem {
  namePath: string;
  path: string;
  icon: React.ReactNode;
}

const mobileNavItems: Array<MobileNavItem> = [
  {
    namePath: "menu.home",
    path: "/",
    icon: <HomeIcon size={20} />,
  },
  {
    namePath: "menu.profile",
    path: `/profile?profileTab=${ProfileScreens.Battles}`,
    icon: <UsersIcon />,
  },
  {
    namePath: "menu.battles",
    path: "/battles",
    icon: <BattleIcon />,
  },
  {
    namePath: "menu.wallet",
    path: "/wallet",
    icon: <WalletIcon size={20} />,
  },
  {
    namePath: "menu.hypeboards",
    path: "/hype-boards",
    icon: <GaugeIcon />,
  },
  {
    namePath: "menu.leaderboard",
    path: `/${LEADERBOARD}`,
    icon: <PodiumIcon />,
  },
];

export const mobileMainNavItems: Array<MobileNavItem> = [
  mobileNavItems.find(({ namePath }) => namePath === "menu.profile")!,
  mobileNavItems.find(({ namePath }) => namePath === "menu.battles")!,
  mobileNavItems.find(({ namePath }) => namePath === "menu.wallet")!,
];

export const mobileSecondaryNavItems: Array<MobileNavItem> =
  mobileNavItems.filter(
    ({ namePath }) =>
      !["menu.profile", "menu.battles", "menu.wallet"].includes(namePath)
  );
